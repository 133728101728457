import { useState, useMemo } from "react";
import { Controller, Form, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import FormSubmitted from "./FormSubmitted";
import Help from "./Help";

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import StyledButton from "../../NurtureProspects/ReusableComponents/Button";
import { BsArrowReturnLeft } from "react-icons/bs";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import moment from "moment/moment";

// ----------------------------------------------------------------------------------------
// STYLES
// ----------------------------------------------------------------------------------------

const styles = {
  container: {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    paddingBlock: "4rem",
    "::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url('/images/linkedinBg.png')",
      backgroundSize: "cover",
      zIndex: -1,
    },
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginTop: "2rem",
    maxWidth: "510px",
    width: "510px",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
    background: "#fff",
    padding: "24px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    fontFamily: "Inter",

    "& h3": {
      fontSize: "24px",
      fontWeight: "600",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },

    "& p": {
      fontSize: "16px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },
  },
  submitBtn: {
    fontSize: "16px",
    fontWeight: "600",
    border: "2px solid rgba(10, 114, 195, 1)",
    background: "rgba(10, 114, 195, 1)",
    padding: "16px 32px",
    boxShadow: "0px 2px 8px 0px rgba(10, 114, 195, 0.2)",
    height: "42px",
    transition: "all .3s",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": { background: "#085b9c", borderColor: "#085b9c" },
    "&:disabled": { background: "#84b8e1", borderColor: "#84b8e1" },
  },
  label: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    color: "rgba(121, 121, 121, 1)",
    marginBlock: "0",
  },
  mainBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "8px",
    fontFamily: "Inter",
    border: "2px solid rgba(10, 114, 195, 1)",
    background: "rgba(10, 114, 195, 1)",
    textTransform: "none",
    lineHeight: "100%",
    transition: "all .3s",
    cursor: "pointer",
    "&:hover": { background: "#085b9c", borderColor: "#085b9c" },
  },
  continueBtn: {
    padding: "16px",
    height: "48px",
    border: "2px solid rgba(0, 0, 0, 0.1)",
    "& svg": {
      height: "24px",
      width: "24px",
    },
  },

  returnBtn: {
    fontFamily: "Inter",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    background: "rgba(10, 114, 195, 0.1)",
    color: "rgba(10, 114, 195, 1)",
    padding: "8px",
    borderRadius: "8px",
    textTransform: "none",
    alignSelf: "flex-start",
    "& span": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "16px",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "&:hover": { background: "#f3f8fc" },
    "& svg": {
      height: "16px",
      width: "16px",
    },
  },
};

// ----------------------------------------------------------------------------------------
// MAIN
// ----------------------------------------------------------------------------------------

const saveUninstallSurveyResponseUrl = "https://hooks.zapier.com/hooks/catch/8876124/2dii72l/";
const continueUsingExtLink = "https://chromewebstore.google.com/detail/nelhhkchoapcbpcgpmmiahfkcdhgecaf";

const ExitSurveyEngageAI = () => {
  const [step, setStep] = useState("chooseReason");
  const [isLoading, setIsLoading] = useState(false);

  const {
    watch,
    control: formController,
    handleSubmit,
  } = useForm({
    defaultValues: { reason: "", message: "" },
    values: { reason: "", message: "" },
  });

  const formValues = watch();

  const reasons = useMemo(
    () => [
      {
        reason: "Switching to another buisness growth tool",
        question: "Can you please provide the name or link to the new tools you use and explain why they are better?",
      },
      {
        reason: "Too many technical issues (bugs, loads forever, glitches)",
        question: "Please let us know which feature you find difficult to use and how we can improve it.",
      },
      { reason: "Not enough features / functionalities", question: "Which functionality would you like us to add?" },

      {
        reason: "Too complex or confusing to use",
        question: "Can you specify which aspects you find too complex or confusing?",
      },
      { reason: "Pricing", question: "Can you please tell us why our pricing does not match the value we provide?" },
      { reason: "Other", question: "Can you please share more details about why you're uninstalling?" },
    ],
    []
  );

  const onContinueUsing = (ev) => {
    ev.preventDefault();
    window.open(continueUsingExtLink, "_blank");
  };

  const onSubmit = async (formData) => {
    try {
      if (step === "submitted") return;
      setIsLoading(true);
      const payload = {
        ...formData,
        date: moment().format("YYYY-MM-DD HH:mm"),
      };

      const response = await fetch(saveUninstallSurveyResponseUrl, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      if (response.ok) setStep("submitted");
      if (response.error) throw response.error;
    } catch (err) {
      toast.error("Failed to submit your response. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const correspondingQuestion = useMemo(() => {
    if (!formValues.reason) return "";
    return reasons.find((r) => r.reason === formValues.reason).question;
  }, [formValues.reason, reasons]);

  const submitDisabled = useMemo(() => {
    if (step === "chooseReason" && !formValues.reason) return true;
    if (step === "message" && !formValues.message) return true;
  }, [step, formValues]);

  // ----------------------------------------------------------------------------
  // COMPONENT RENDERING
  // ----------------------------------------------------------------------------

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          position: "absolute",
          top: "1rem",
          left: "0",
          paddingInline: "2rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src="/images/engageAiWhiteLogo.svg" alt="Engage AI" />
        <StyledButton sx={{ ...styles.mainBtn, ...styles.continueBtn }} onClick={onContinueUsing}>
          <BrowserUpdatedIcon />
          <span>Continue using Engage AI</span>
        </StyledButton>
      </Box>

      <Box sx={{ ...styles.dialog, ...(step === "submitted" && { width: "430px" }) }}>
        {step !== "submitted" && (
          <Box sx={styles.title}>
            <h3>We're sorry to see you go 😔</h3>
            <p>Tell us why you're uninstalling the Engage AI extension</p>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap="16px">
          <Form id="exitSurveyForm" control={formController} onSubmit={handleSubmit(onSubmit)}>
            {step === "chooseReason" && (
              <FormControl>
                <Controller
                  name="reason"
                  control={formController}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup {...field} sx={{ gap: "8px" }} name="reason">
                      {reasons.map((r, i) => (
                        <FormControlLabel
                          sx={styles.label}
                          key={i}
                          value={r.reason}
                          control={<Radio />}
                          label={r.reason}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
            {step === "message" && (
              <Box display="flex" flexDirection="column" gap="16px">
                <Button sx={styles.returnBtn} onClick={() => setStep("chooseReason")}>
                  <BsArrowReturnLeft />
                  <span>{formValues.reason}</span>
                </Button>
                <Box display="flex" flexDirection="column" gap="12px">
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      color: "rgba(65, 80, 88, 1)",
                      margin: "0",
                    }}
                  >
                    {correspondingQuestion}
                  </p>
                  <Controller
                    name="message"
                    control={formController}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        placeholder="Type here"
                        multiline
                        minRows={6}
                        sx={{
                          border: "1px solid rgba(224, 226, 228, 1)",
                          borderRadius: "8px",
                          "& textarea": {
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            color: "rgba(25, 25, 25, 1)",
                            paddingRight: "5px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
            {step === "submitted" && <FormSubmitted onContinue={onContinueUsing} />}
          </Form>
          {step !== "submitted" &&
            (step === "message" ? (
              <StyledButton type="submit" form="exitSurveyForm" disabled={submitDisabled} sx={styles.submitBtn}>
                {isLoading ? <CircularProgress /> : "Submit response"}
              </StyledButton>
            ) : (
              <StyledButton disabled={submitDisabled} sx={styles.submitBtn} onClick={() => setStep("message")}>
                {isLoading ? <CircularProgress /> : "Share"}
              </StyledButton>
            ))}
        </Box>
      </Box>
      <Help />
    </Box>
  );
};
export default ExitSurveyEngageAI;
