import Gleap from "gleap";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import GetHomePage from "../../Services/HomePageService";
import Spinner from "../Spinner/Spinner";
// import Calendly from "./Calendly";
//import Agreement from '../Agreement/Agreement';
//import { checkServiceResponse } from '../../Services/AuthService';
import styles from "./Homepage.module.css";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../Store/Store";
import { MarkSuggestionBoxService } from "../../Services/MarkComplete";
import { setLinkDataLocalStorage, setStatsLocalStorage } from "../../Scripts/getSetLocalStorage";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Profitwell from "./Profitwell";
import { boostButtonIcon, boostIcon, boostIconZero, checkIcon, infoIcon, infoIconDataBox } from "./HomepageIcons";

const infoIconTooltip = (props, message) => (
  <div
    className={`${styles["tooltip"]} mulish`}
    {...props}
    style={{
      backgroundColor: "#ffff",
      ...props.style,
    }}
  >
    {message}
  </div>
);

const Homepage = () => {
  const [loading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stats, setStats] = useState();
  const [superboostQuantity, setSuperboostQuantity] = useState();
  const [suggestionBox, setSuggestionBox] = useState({ text: {}, userCompleted: {} });
  const [state, dispatch] = useContext(Context);
  // eslint-disable-next-line
  // const userData = useState(state.user.data);
  let history = useNavigate();
  let busy = false;

  useEffect(() => {
    Gleap.identify(state.user.data.id, {
      name: state.user.data.firstName + " " + state.user.data.lastName,
      email: state.user.data.email,
      plan: state.user.data.group,
    });
    const s = JSON.parse(localStorage.getItem("stats"));

    if (s) {
      // TODO usually console.logs are removed for prod
      console.log("retrieving user stats from local storage...", s.stats);
      setSuperboostQuantity(s.superboost_quantity);
      setStats(s.stats);
      setSuggestionBox({
        text: s.suggestions_box.suggestions_text,
        userCompleted: s.suggestions_box.user_suggestion_completeness,
      });
    } else {
      GetHomePage()
        .then((res) => {
          res
            .json()
            .then((json) => {
              setIsLoading(false);
              try {
                // Set last submitted link and date if exists
                if (json.data.link && json.data.date_submitted) {
                  const data = {
                    dateSubmitted: json.data.date_submitted,
                    link: json.data.link,
                    pod: json.data.pod,
                  };
                  setLinkDataLocalStorage(data);
                  dispatch({
                    type: "SET_CURRENT_LINK",
                    payload: {
                      data: data,
                    },
                  });
                }
                setStatsLocalStorage(json.data);
                setSuperboostQuantity(json.data.superboost_quantity);
                setStats(json.data.stats);
                setSuggestionBox({
                  text: json.data.suggestions_box.suggestions_text,
                  userCompleted: json.data.suggestions_box.user_suggestion_completeness,
                });
              } catch (error) {
                console.error(error);
                // TODO add toast or something to alert user that their data didn't load 3/13/21
              }
            })
            .catch((error) => {
              setIsLoading(false);
              //TODO add toast or something to alert user that their data didn't load 3/13/21

              console.error(error);
            });
        })
        .catch((error) => {
          setIsLoading(false);
          //TODO add toast or something to alert user that their data didn't load 3/13/21

          console.error(error);
        });
    }
  }, [history, dispatch, state.user]);

  const modalHandler = () => {
    // TODO Should we use the stored superboost or should we read it from the database for each click
    if (!superboostQuantity) {
      setShowModal(true);
    } else {
      // Non-zero superboost quantity re-routes to the calendar
      window.open("https://calendly.com/engage-ai/book-superboost-for-your-linkedin-post");
    }
  };

  const modalBody = (
    <>
      <div className="">
        <span className={`${styles["boost-body-style"]}`}>
          <span role="img" aria-label="bullet">
            🔷
          </span>{" "}
          Hey {state.user.data ? state.user.data.firstName : ""}, you currently have <strong>0</strong> Superboosts
          available
        </span>
      </div>

      <div className={`${styles["boost-body-style"]}  mt-4 mb-2`}>
        <span role="img" aria-label="rocket">
          🚀
        </span>{" "}
        <strong>What are Superboosts?</strong>
      </div>
      <div className={`${styles["boost-body-style"]} mt-3`}>
        Instead of receiving the default number of comments - 15, you can exercise the use of Superboost for chosen
        posts to which they will receive more than 15 comments. This will be useful when you have an important post that
        requires more tractions.
      </div>

      <div className={`${styles["boost-body-style"]} mt-4`}>
        <span role="img" aria-label="gift">
          🎁
        </span>{" "}
        <strong>Spread the love to your closest allies, clients, and coworkers.</strong>
      </div>

      <div className={`${styles["boost-body-style"]} mt-4`}>
        {state.user.data ? state.user.data.firstName : ""}, get gifted 3 Superboosts per friend who joins. Get started{" "}
        <a href="https://filtpod.firstpromoter.com/signup/10688" target="_blank" rel="noopener noreferrer">
          HERE
        </a>
        .
      </div>
      <div className={`${styles["boost-dismiss-style"]} mt-5`} onClick={() => setShowModal(false)}>
        DISMISS
      </div>
    </>
  );

  // const survey = (
  //   <iframe
  //     src='https://docs.google.com/forms/d/e/1FAIpQLSfieSSKiiFrs0qILYN7BPk4Vu41_6M8NJxqYtSd6gPVriveFA/viewform?embedded=true'
  //     width='100%'
  //     height='100%'
  //     frameBorder='0'
  //     marginHeight='0'
  //     marginWidth='0'
  //     title='survey'
  //   >
  //     Loading…
  //   </iframe>
  // );

  /**
   *
   * @param {number} idx index of the suggestion box
   * @param {boolean} currentBoolean
   * @returns
   */
  const toggleCheckbox = (idx, currentBoolean) => {
    if (busy) return;
    busy = true;
    const completedBoolean = !currentBoolean;
    MarkSuggestionBoxService(idx, completedBoolean)
      .then((res) => {
        if (res.status === 200) {
          // Update state
          const newValue = { ...suggestionBox.userCompleted[idx], completed: completedBoolean };
          const newUserCompleted = { ...suggestionBox.userCompleted, [idx]: newValue };
          const newSuggestionBox = { ...suggestionBox, userCompleted: newUserCompleted };
          setSuggestionBox(newSuggestionBox);
          busy = false;
        }
        // TODO Update the suggestionBox state
      })
      .catch((error) => {
        busy = false;
        console.error(error);
      });
  };

  /**
   * Extract link if any and create link in HTML element
   *
   * @param {string} text
   * @return {element} element HTML element
   */
  const createLink = (text) => {
    const exp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    const url = text.match(exp);
    let htmlElement;
    if (url) {
      htmlElement = (
        <span>
          <a href={url[0]} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        </span>
      );
    } else {
      htmlElement = <span>{text}</span>;
    }
    return htmlElement;
  };

  return (
    <>
      <Profitwell authToken="bd5c49b3d18639da365a7049e1a64a6c" email={state.user?.data?.email} />
      <div>
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div style={{ flex: "1" }}>
            {loading ? <Spinner /> : ""}
            <div className={`${styles["background"]}`}>
              <div className={`${styles["main"]} mt-5 `}>
                <div className={`row`}>
                  <div className="col-sm-8">
                    <div className=" no-gutters">
                      <p className={`${styles["title"]} h4 mb-3`}>Personal Summary</p>
                      <div className={`${styles["data-box-parent"]} d-flex no-gutters`}>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Posts since {stats ? stats.quarter : ""}</p>

                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) =>
                                  infoIconTooltip(props, "The sum of posts you made in this quarter (calendar year).")
                                }
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-blue">{stats ? stats.user_all_submissions_count : ""}</p>
                          </div>
                        </div>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Posts made weekly</p>
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) =>
                                  infoIconTooltip(
                                    props,
                                    "The sum of posts you made this week in this quarter (calendar year)."
                                  )
                                }
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-purple">{stats ? stats.user_last_seven_submissions_count : ""}</p>
                          </div>
                        </div>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Reputation Score</p>
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) => infoIconTooltip(props, "Average Reputation Score.")}
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-black">{stats ? stats.rep_score : ""}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" no-gutters">
                      <p className={`${styles["title"]} h4 mb-3 mt-4`}>Top 5% Business Owners in the Community</p>
                      <div className={`${styles["data-box-parent"]} d-flex no-gutters`}>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Posts since {stats ? stats.quarter : ""}</p>
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) =>
                                  infoIconTooltip(
                                    props,
                                    "The average sum of posts made in this quarter (calendar year)."
                                  )
                                }
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-blue">{stats ? stats.top_five_all_sub_count_avg : ""}</p>
                          </div>
                        </div>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Posts made weekly</p>
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) =>
                                  infoIconTooltip(
                                    props,
                                    "The average sum of posts made this week in this quarter (calendar year)."
                                  )
                                }
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-purple">{stats ? stats.top_five_last_seven_count_avg : ""}</p>
                          </div>
                        </div>
                        <div className={`${styles["data-box-child"]}  `}>
                          <div className={`${styles["data-box-child-content"]} mulish `}>
                            <div className={`${styles["stat-title-wrapper"]} `}>
                              <p className="mulish stat-head">Reputation Score</p>
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={(props) => infoIconTooltip(props, "Average Reputation Score.")}
                              >
                                <div>{infoIconDataBox}</div>
                              </OverlayTrigger>
                            </div>
                            <p className="mulish stat-black">{stats ? stats.top_five_rep_score_avg : ""}</p>
                          </div>
                        </div>
                      </div>
                      {state.user?.data?.ui["homepage-survey"] ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: state.user?.data?.ui["homepage-survey"],
                          }}
                          className={`${styles["div-container-style"]} ${styles["row-mr-space"]} col-sm-12 mt-4`}
                        ></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <div className={`col-sm-4 `} id="more-engagements-element">
                    <div className={`${styles["boost-container"]}`}></div>
                    <div className={`${styles["container-data-boost"]} align-content-center justify-content-center`}>
                      <div className={`${styles["container-boost-header"]} d-flex justify-content-center `}>
                        <div
                          className={`${styles["dot"]} d-flex align-content-center flex-wrap justify-content-center`}
                        >
                          {boostButtonIcon}
                        </div>
                      </div>
                      <div className={`${styles["container-boost-body"]} d-flex  justify-content-center `}>
                        <span className={`${styles["boost-body-text"]} align-content-center`}>
                          Get More Engagements
                        </span>
                      </div>

                      <div className={` d-flex justify-content-center mb-3 align-content-center`}>
                        <div className={`${styles["boost-text-small"]} flex-wrap  align-content-center`}>
                          <span className={`${styles["boost-text-small"]} stat-head`}>My Superboosts</span>
                        </div>
                        <div
                          className={`${styles["dot-small"]} ml-2 d-flex align-content-center flex-wrap justify-content-center`}
                        >
                          <span className={`${styles["boost-number-small"]}`}>
                            {superboostQuantity ? superboostQuantity : 0}
                          </span>
                        </div>
                      </div>

                      <div className={`${styles["container-boost-footer"]} d-flex justify-content-center`}>
                        <button
                          className={
                            superboostQuantity ? `${styles["boost-button"]} ` : `${styles["boost-button-zero"]} `
                          }
                          onClick={() => modalHandler()}
                        >
                          <span>{superboostQuantity ? "Schedule a Boost" : "Get Superboosts"}</span>
                          <span className="ml-4 ">{superboostQuantity ? boostIcon : boostIconZero}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles["container-data-boost"]} align-content-center justify-content-center`}
                style={{
                  height: "3rem",
                  padding: "1em",
                  cursor: "pointer",
                  marginTop: "2em",
                  width: "270px",
                  fontWeight: 700,
                  background: "#8490E2",
                }}
                onClick={() => {
                  window.open(
                    "https://docs.google.com/document/d/1y4Lf4etMDP1Yo5ge3-5LOLYm3R_VNhh5I15HHg2qsho/edit?usp=sharing"
                  );
                }}
              >
                <div style={{ textAlign: "center" }}>Pod Help Center</div>
              </div>
              <div className={`${styles["container-vertial-box"]} ${styles["row-ml-space"]} p-2`}>
                <div className={`${styles["title"]}  mt-3`}>Step-By-Step Tips To Up Your Reach By 5x</div>
                <hr></hr>
                {/* TODO move this to it's own file */}
                {Object.keys(suggestionBox.text).length > 0 ? (
                  Object.keys(suggestionBox.text).map((databaseIndex, idx) => {
                    return (
                      <section key={idx}>
                        {suggestionBox.userCompleted[databaseIndex] ? (
                          <>
                            <div className="d-inline-flex">
                              <div
                                className={
                                  suggestionBox.userCompleted[databaseIndex].completed === true
                                    ? `${styles["checkbox-style"]}  ${styles["checkbox-incomplete"]}  d-flex align-content-center flex-wrap mr-3`
                                    : `${styles["checkbox-style"]}  ${styles["checkbox-complete"]}  d-flex align-content-center flex-wrap mr-3`
                                }
                                onClick={() =>
                                  toggleCheckbox(databaseIndex, suggestionBox.userCompleted[databaseIndex].completed)
                                }
                              >
                                {suggestionBox.userCompleted[databaseIndex].completed === true ? (
                                  <span>{checkIcon}</span>
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </div>
                              <div>
                                <span
                                  className={
                                    suggestionBox.userCompleted[databaseIndex].completed === true
                                      ? `${styles["body-text"]} mr-2`
                                      : `${styles["body-text-light"]} mr-2`
                                  }
                                >
                                  {createLink(suggestionBox.text[databaseIndex])}
                                </span>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ) : (
                          <div></div>
                        )}
                      </section>
                    );
                  })
                ) : (
                  <>
                    <div className="d-inline-flex">
                      <div className="mr-3">{infoIcon}</div>
                      <div>
                        <span className={`${styles["body-text"]}`}></span>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="d-inline-flex">
                      <div className="mr-3">{infoIcon}</div>
                      <div>
                        <span className={`${styles["body-text"]}`}></span>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="d-inline-flex">
                      <div className="mr-3">{infoIcon}</div>
                      <div>
                        <span className={`${styles["body-text"]}`}></span>
                      </div>
                    </div>
                    <hr></hr>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showModal}
          size="lg"
          onHide={() => setShowModal(false)}
          dialogClassName={`${styles["modal-90w"]} modal-dialog-centered`}
          aria-labelledby="boost-modal"
          // centered
        >
          <Modal.Body>{modalBody}</Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Homepage;
