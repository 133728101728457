// ----------------------------------------------------------------------------
// IMPORTS
// ----------------------------------------------------------------------------
import { useState } from "react";
// import { useContext, useState } from "react";
// import { useGoogleLogin } from "@react-oauth/google";
// import toast from "react-hot-toast";

// import LoginService from "../../../Services/LoginService";
// import { Context } from "../../../Store/Store";

import SignupLogin from "./SignupLogin";
import PinExtension from "./PinExtension";
import StartEngaging from "./StartEngaging";
// import { handleSuccessLogin } from "./Common";

import { Dialog, DialogTitle, DialogContent, Box, Button } from "@mui/material";
import { MdLightbulbOutline } from "react-icons/md";
// import { FcGoogle } from "react-icons/fc";

// ----------------------------------------------------------------------------
// STYLES
// ----------------------------------------------------------------------------

const styles = {
  container: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url('/images/linkedinBg.png')",
      backgroundSize: "cover",
      zIndex: -1,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "32px",
    textAlign: "center",
    fontFamily: "Inter",
    "& h3": {
      fontSize: "24px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
      "& strong": {
        fontWeight: "600",
      },
    },
    "& p": {
      fontSize: "16px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.7)",
      margin: "0",
    },
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& button": {
      display: "flex !important",
      alignitems: "center !important",
      justifyContent: "center !important",
      gap: "12px !important",
      borderRaidus: "8px !important",
      fontFamily: "Inter !important",
      fontWeight: "500 !important",
      fontSize: "16px !important",
      padding: "12px 24px !important",
      color: "rgba(121, 121, 121, 1) !important",
      border: "1px solid rgba(229, 229, 229, 1) !important",
      textTransform: "none",
      lineHeight: "100%",
      transition: "all .3s",
      height: "48px",
      boxShadow: "none !important",
      margin: "0 !important",

      "& > *": {
        margin: "0 !important",
        padding: "0 !important",
      },

      "&:hover": {
        background: "#fdfdfd !important",
      },

      "& svg": {
        height: "18px",
        width: "18px",
      },
    },
  },
  suggestionbox: {
    display: "flex",
    alignItems: "start",
    gap: "8px",
    padding: "12px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#053a64",
    border: "1px solid rgba(196, 221, 241, 1)",
    background: "rgba(10, 114, 195, 0.05)",
    borderRadius: "8px",

    "& span": {
      textAlign: "left",
    },

    "& svg": {
      height: "32px",
      width: "32px",
      color: "rgba(10, 114, 195, 1)",
    },
  },
  skipBtn: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "16px",
    sbackground: "none",
    color: "rgba(10, 114, 195, 1)",
    lineHeight: "100%",
    padding: "0",
    textTransform: "none",
    "&:hover": { background: "none" },
  },
};

// ----------------------------------------------------------------------------

const WelcomeEngageAI = () => {
  // const context = useContext(Context);
  const [step, setStep] = useState("welcome");
  const [isNewUser, setIsNewUser] = useState(false);

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       const resp = await LoginService({ accessToken: tokenResponse.access_token });
  //       const json = await resp.json();

  //       if (resp.status === 200) {
  //         handleSuccessLogin(json, context);
  //         setStep("pin");
  //       }
  //     } catch (err) {
  //       toast.error("Failed to login with Google. Please try again later.");
  //     }
  //   },
  // });

  const onContinueEmail = () => {
    setStep("signup");
  };

  return (
    <Box sx={styles.container}>
      <Dialog
        hideBackdrop
        open={true}
        PaperProps={{
          sx: {
            maxWidth: "400px",
            width: "400px",
            borderRadius: "16px",
            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        {step === "engage" && <img src="/images/commentBox.png" alt="Comment box" />}
        {step === "pin" && <img src="/images/pinExtension.png" alt="pin extension" />}
        {step !== "pin" && step !== "engage" && (
          <DialogTitle sx={{ padding: "24px !important" }}>
            <Box display="flex" justifyContent="center" width="100%">
              <img src="/images/loginLogo.svg" alt="engage ai logo" />
            </Box>
          </DialogTitle>
        )}
        <DialogContent sx={{ padding: "0 24px 24px 24px !important" }}>
          {step === "welcome" && (
            <Box sx={styles.content}>
              <Box display="flex" flexDirection="column" gap="16px">
                <h3>
                  Welcome to <strong>Engage AI</strong>👋
                </h3>
                <p>Unlock more features with a free account</p>
              </Box>
              <Box sx={styles.main}>
                <Box sx={styles.suggestionbox}>
                  <MdLightbulbOutline />
                  <span>
                    We recommend using your <strong>work email</strong> - it keeps work and life separate.
                  </span>
                </Box>
                {/* <Button onClick={() => googleLogin()}>
                  <FcGoogle />
                  <span>Continue with Google</span>
                </Button> */}
                <Button onClick={onContinueEmail}>
                  <span>Continue with Email</span>
                </Button>
              </Box>
              <Button sx={styles.skipBtn} onClick={() => setStep("pin")}>
                Skip this step
              </Button>
            </Box>
          )}
          {(step === "signup" || step === "login") && (
            <SignupLogin step={step} setStep={setStep} setIsNewUser={setIsNewUser} />
          )}
          {step === "pin" && <PinExtension isNewUser={isNewUser} onDoneClick={() => setStep("engage")} />}
          {step === "engage" && <StartEngaging />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default WelcomeEngageAI;
